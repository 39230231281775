import "foundation-datepicker/js/locales/foundation-datepicker.ar"
import "foundation-datepicker/js/locales/foundation-datepicker.az"
import "foundation-datepicker/js/locales/foundation-datepicker.bg"
import "foundation-datepicker/js/locales/foundation-datepicker.bs"
import "foundation-datepicker/js/locales/foundation-datepicker.ca"
import "foundation-datepicker/js/locales/foundation-datepicker.cs"
import "foundation-datepicker/js/locales/foundation-datepicker.cy"
import "foundation-datepicker/js/locales/foundation-datepicker.da"
import "foundation-datepicker/js/locales/foundation-datepicker.de"
import "foundation-datepicker/js/locales/foundation-datepicker.el"
import "foundation-datepicker/js/locales/foundation-datepicker.en-GB"
import "foundation-datepicker/js/locales/foundation-datepicker.eo"
import "foundation-datepicker/js/locales/foundation-datepicker.es"
import "foundation-datepicker/js/locales/foundation-datepicker.et"
import "foundation-datepicker/js/locales/foundation-datepicker.eu"
import "foundation-datepicker/js/locales/foundation-datepicker.fa"
import "foundation-datepicker/js/locales/foundation-datepicker.fi"
import "foundation-datepicker/js/locales/foundation-datepicker.fo"
import "foundation-datepicker/js/locales/foundation-datepicker.fr-CH"
import "foundation-datepicker/js/locales/foundation-datepicker.fr"
import "foundation-datepicker/js/locales/foundation-datepicker.gl"
import "foundation-datepicker/js/locales/foundation-datepicker.he"
import "foundation-datepicker/js/locales/foundation-datepicker.hr"
import "foundation-datepicker/js/locales/foundation-datepicker.hu"
import "foundation-datepicker/js/locales/foundation-datepicker.hy"
import "foundation-datepicker/js/locales/foundation-datepicker.id"
import "foundation-datepicker/js/locales/foundation-datepicker.is"
import "foundation-datepicker/js/locales/foundation-datepicker.it-CH"
import "foundation-datepicker/js/locales/foundation-datepicker.it"
import "foundation-datepicker/js/locales/foundation-datepicker.ja"
import "foundation-datepicker/js/locales/foundation-datepicker.ka"
import "foundation-datepicker/js/locales/foundation-datepicker.kh"
import "foundation-datepicker/js/locales/foundation-datepicker.kk"
import "foundation-datepicker/js/locales/foundation-datepicker.ko"
import "foundation-datepicker/js/locales/foundation-datepicker.kr"
import "foundation-datepicker/js/locales/foundation-datepicker.lv"
import "foundation-datepicker/js/locales/foundation-datepicker.me"
import "foundation-datepicker/js/locales/foundation-datepicker.mk"
import "foundation-datepicker/js/locales/foundation-datepicker.mn"
import "foundation-datepicker/js/locales/foundation-datepicker.ms"
import "foundation-datepicker/js/locales/foundation-datepicker.nb"
import "foundation-datepicker/js/locales/foundation-datepicker.nl-BE"
import "foundation-datepicker/js/locales/foundation-datepicker.nl"
import "foundation-datepicker/js/locales/foundation-datepicker.no"
import "foundation-datepicker/js/locales/foundation-datepicker.pl"
import "foundation-datepicker/js/locales/foundation-datepicker.pt-br"
import "foundation-datepicker/js/locales/foundation-datepicker.pt"
import "foundation-datepicker/js/locales/foundation-datepicker.ro"
import "foundation-datepicker/js/locales/foundation-datepicker.rs"
import "foundation-datepicker/js/locales/foundation-datepicker.rs-latin"
import "foundation-datepicker/js/locales/foundation-datepicker.ru"
import "foundation-datepicker/js/locales/foundation-datepicker.sk"
import "foundation-datepicker/js/locales/foundation-datepicker.sl"
import "foundation-datepicker/js/locales/foundation-datepicker.sq"
import "foundation-datepicker/js/locales/foundation-datepicker.sr"
import "foundation-datepicker/js/locales/foundation-datepicker.sr-latin"
import "foundation-datepicker/js/locales/foundation-datepicker.sv"
import "foundation-datepicker/js/locales/foundation-datepicker.sw"
import "foundation-datepicker/js/locales/foundation-datepicker.th"
import "foundation-datepicker/js/locales/foundation-datepicker.tr"
import "foundation-datepicker/js/locales/foundation-datepicker.uk"
import "foundation-datepicker/js/locales/foundation-datepicker.vi"
import "foundation-datepicker/js/locales/foundation-datepicker.zh-CN"
import "foundation-datepicker/js/locales/foundation-datepicker.zh-TW"

import "src/decidim/foundation-datepicker/js/locales/foundation-datepicker.lb"

